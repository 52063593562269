<template>
  <div>
      <CoolLightBox
      class="align-items-center"
      :items="itemImage"
      :index="showImage"
      @close="showImage = null"
    />
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      @reload="$refs['refMerchandising'].refresh()"
    >
      <b-table
        slot="table"
        ref="refMerchandising"
        small
        no-provider-filtering
        :api-url="'/logistics/merchandising/get-all-products-inventory'"
        :items="myProvider"
        :fields="arrayColumns"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        sticky-header="70vh"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <!-- Column # -->
        <template #cell(selected)="data">
          <div>
            <b-form-checkbox
              v-model="merchan"
              :value="{ id: data.item.id, merchandising_id: data.item.merchandising_id }"
              @change="selectAddOn(data.item)"
            />
          </div>
        </template>

        <!-- Column IMAGE -->
        <template #cell(url_image)="data">
          <div class="image-upload">
            <input
              id="file_input"
              type="file"
              hidden
            >
            <div class="form-group">

              <div
                v-b-tooltip.hover.left="'Click to view flyer'"
                class="d-flex flex-column justify-content-center align-items-start clickable"
              >
                <img
                  v-if="data.item.image_product"
                  width="80"
                  height="80"
                  :src="data.item.image_product"
                  icon="file-earmark-image-fill"
                  @click="openImage(data)"
                >
                <img
                  v-else
                  width="80"
                  height="80"
                  src="/assets/images/product.png"
                >
              </div>

              <!-- <figure>
                <img
                  v-if="data.item.image_product"
                  width="80"
                  height="80"
                  :src="data.item.image_product"
                  class="clickable"
                  v-b-tooltip.hover.left="'Click to view flyer'"
                  @click="openImage(data.id)"
                >
                <img
                  v-else
                  width="80"
                  height="80"
                  src="/assets/images/product.png"
                >
              </figure> -->
            </div>
          </div>
        </template>

        <!-- Column Client -->
        <template #cell(client)="data">
          {{ data.item.first_name }} {{ data.item.last_name }}
        </template>

        <template #cell(detail)="data">
            <span v-b-tooltip.hover.right="data.item.detail">
              <change-sms :sms-data="data.item.detail == 'null' ?  '' : data.item.detail" :text-length="20"
               :variant="'info'" type-show-more="span" />
            </span>            
        </template>

        <template #cell(commentary)="data">
            <span v-b-tooltip.hover.right="data.item.commentary">
              <change-sms :sms-data="data.item.commentary == 'null' ? '' : data.item.commentary" :text-length="20"
               :variant="'info'" type-show-more="span" />
            </span>            
        </template>

        <!-- Column Amount Income -->
        <template #cell(amountIncome)="data">
          {{ data.item.currency +' ' }} {{ data.item.amount_income_product | currency }}
        </template>

        <!-- Column Quantity -->

        <template #cell(quantity)="data">
          <div class="d-flex justify-content-center">
            {{ data.item.quantity }}
          </div>
        </template>

        <template #cell(amount_expense)="data">
          {{ data.item.currency +' ' }} {{ data.item.amount_expense | currency }}
        </template>

        <!-- Column Balance -->
        <template #cell(balance)="data">
          <b-badge
            :class="data.item.balance > 1? 'bg-light-success': 'bg-light-danger'"
            class="text-center w-100"
            pill
          >

            {{ data.item.currency +' ' }} {{ data.item.balance | currency }}

          </b-badge>
        </template>

        <!-- Column Created at -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | myGlobalDay }}
        </template>

        <!-- Columns ACTIONS -->
        <template #cell(actions)="data">
          <div class="w-100 d-flex justify-content-center">
            <feather-icon
              icon="Edit2Icon"
              class="cursor-pointer text-warning"
              style="margin-right: 0.5rem"
              size="16"
              @click="editProduct(data.item)"
            />
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="16"
              @click="deleteProduct(data.item.id)"
            />
          </div>
        </template>
      </b-table>
    </filter-slot>

    <register-merchandising
      v-if="modalRegister"
      :product-id="selectedProductId"
      @close="closeModalEdit"
      @justClose="closeModal"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import vSelect from 'vue-select';
import CoolLightBox from "vue-cool-lightbox";
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot.vue';
import MerchandisingService from '../services/merchandising.service';
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import ChangeSms from '@/views/commons/components/clients/components/clientsSms/ChangeSms.vue'
// Import Filter

import fields from '../data/fields.merchandisinggrid.data';
import filters from '../data/filter.merchandising.data';

// MODAL
import RegisterMerchandising from '../modals/RegisterMerchandising.vue';

export default {
  components: {
    vSelect,
    FilterSlot,
    CoolLightBox,
    ChangeSms,
    'register-merchandising': RegisterMerchandising,
  },
  props: {
    //
  },
  data() {
    return {
      productId: '',
      modalRegister: false,
      sortBy: 'created_at',
      sortDesc: true,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Product...',
        model: '',
      },
      startPage: null,
      toPage: null,
      arrayColumns: fields,
      filter: filters,
      isBusy: false,
      selectedProductId: null,
      showImage: null,
      itemImage: [],
      mainProps: {
        center: false,
        fluidGrow: false,
        width: 80,
        height: 80,
        block: false,
        fluid: true,
      },
      items: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  async mounted() {
    // this.filter[2].options = [
    //   { id: 1, name: 'Profitable' },
    //   { id: 2, name: 'Non Profitable' },
    // ];
    const responseProvider = await MerchandisingService.getProviderList({});
    this.filter[2].options = responseProvider.data;
    const responseProgram = await MerchandisingService.getProgramList({});
    this.filter[3].options = responseProgram.data;
  },
  methods: {

    async getSelectProvider() {
      try {
        const response = await MerchandisingService.getProviderList({});
        if (response.status == 200) {
          this.optionProvider = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong!',
        );
      }
    },
    async getSelectProgram() {
      try {
        const response = await MerchandisingService.getProgramList({});
        if (response.status == 200) {
          this.optionProgram = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong!',
        );
      }
    },
    isRentable(amount_income_product, amount_expense_product) {
      return amount_income_product > amount_expense_product;
    },
    async myProvider(ctx) {
      try {
        const data = await amgApi.post(
          `${ctx.apiUrl}?page=${ctx.currentPage}`,
          {
            perpage: ctx.perPage,
            name_text: this.filterPrincipal.model,
            from: this.filter[0].model,
            to: this.filter[1].model,
            order: ctx.sortBy == '' ? 'created_at' : ctx.sortBy,
            orderby: ctx.sortDesc == 1 ? 'desc' : 'asc',
            programId: this.filter[3].model,
            providerId: this.filter[2].model,
            // profitable: this.filter[2].model,
          },
        );
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.currentPage = data.data.current_page;        
        this.perPage = data.data.per_page;        
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;        
        this.toPage = data.data.to;
        return  this.items || [];
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    openImage({ item }) {
      this.itemImage = [item.image_product];
      this.showImage = 0;
    },
    editProduct(id) {
      this.modalRegister = true;
      this.selectedProductId = id;
    },
    closeModalEdit() {
      this.modalRegister = false;
      this.$refs.refMerchandising.refresh();
    },
    closeModal() {
      this.modalRegister = false;
    },
    async deleteProduct(idProduct) {
      const result = await this.showConfirmSwal();
      if (result.value) {
        try {
          await MerchandisingService.deleteLogProduct({ idProduct });
          this.showToast();
          this.$refs.refMerchandising.refresh();
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
  },
};
</script>

<style>

</style>
