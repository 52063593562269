export default [
  {
    key: 'url_image',
    label: 'Image',
    class: 'text-left',

  },
  {
    key: 'product',
    label: 'Product',
    class: 'text-left',

  },
  {
    key: 'quantity',
    label: 'Quantity',
    thStyle:{
      textAlign:'center'
    },

  },
  {
    key: 'full_name',
    label: 'Client',
    class: 'text-left ',

  },
  {
    key: 'vendor',
    label: 'Provider',
    class: 'text-left',

  },
  {
    key: 'program',
    label: 'Program',
    class: 'text-left',

  },
  // {
  //   key: 'id_transaction',
  //   label: 'ID Transaction',
  //   class: 'text-left',

  // },
  // {
  //   key: 'amount_income',
  //   label: 'Amount Income',
  //   class: 'text-left',

  // },
  {
    key: 'amount_expense',
    label: 'Amount Expense',
    class: 'text-left',
    sortable: true,
  },
  // {
  //   key: 'balance',
  //   label: 'Balance',
  //   class: 'text-left',

  // },
  {
    key: 'detail',
    label: 'Detail',
    class: 'text-center',

  },
  {
    key: 'commentary',
    label: 'Comment',
    class: 'text-center',

  },
  {
    key: 'created_at',
    label: 'Created At',
    class: 'text-left',
    sortable: true,
  },
  {
    key: 'created_by',
    class: 'text-left',
    sortable: true,
  },
  {
    key: 'actions',
    label: 'Actions',
    thStyle:{
      textAlign:'center'
    },
  },
];
