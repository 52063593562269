<template>
  <div>
    <b-modal
      v-model="onControl"
      :title="productId ? 'EDIT MERCHANDISING' : 'REGISTER NEW MERCHANDISING'"
      :ok-title="'SAVE'"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      @hidden="justCloseModal"
    >
      <ValidationObserver ref="form">
        <div class="mt-1">
          <b-row cols="2">
            <b-col sm="8">
              <b-row>
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="PROGRAM"
                      :state="errors[0] ? false : null"
                    >
                      <v-select
                        v-model="idProgram"
                        :options="optionProgram"
                        :reduce="(program) => program.id"
                        label="value"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <br>
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        <strong>CLIENT</strong>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row cols="1">
                <b-col>
                  <b-form-group
                    label="CLIENT:"
                    label-for="creditorParent"
                  >
                    <vue-autosuggest
                      ref="autocomplete"
                      v-model="clientText"
                      :get-suggestion-value="getSuggestionValue"
                      :suggestions="listClient"
                      :input-props="{
                        id: 'autosuggest__input',
                        class: 'form-control',
                        placeholder: 'Type to search clients',
                        disabled: idProgram == null || idProgram == '',
                      }"
                      @input="getClients"
                      @selected="selectHandler"
                    >
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">
                          {{ suggestion.item.full_name }}
                        </span>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        <strong>VENDORS</strong>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row cols="1">
                <b-col>
                  <feather-icon
                    v-b-tooltip.hover.v-primary
                    icon="PlusCircleIcon"
                    size="20"
                    class="cursor-pointer text-primary"
                    style="float: right; margin-top: -0.3rem"
                    title="ADD VENDOR"
                    @click="openModalAddProvider()"
                  />
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="VENDOR"
                      :state="errors[0] ? false : null"
                    >
                      <v-select
                        v-model="idProvider"
                        :options="optionProvider"
                        :reduce="(provider) => provider.id"
                        label="name"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                cols="1"
                class="mt-1"
              >
                <b-col>
                  <label>COUNTRY:</label>
                  <v-select
                    v-model="idCountry"
                    :options="optionCountries"
                    :reduce="(provider) => provider.id"
                    label="name"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        <strong>PRODUCT</strong>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <label>PRODUCT:</label>
                    <b-form-input
                      v-model="nomProduct"
                      placeholder="Product"
                      :state="errors[0] ? false : null"
                    />
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-group
                      label="QUANTITY:"
                      label-for="QUANTITY"
                    >
                      <b-form-input
                        v-model="quantityProduct"
                        type="number"
                        placeholder="Quantity"
                        :state="errors[0] ? false : null"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col class="mt-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|validate-amount"
                  >
                    <label>PRICE:</label>
                    <money
                      v-model="price"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: currency == '$' ? '$ ' : 'S/ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="form-control"
                                           :class="
                        errors[0] && vmoneyValidate ? 'border-danger' : ''
                      "
                    />
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <ValidationProvider v-slot="{ errors }" rules="required|validate-amount">
                    <label>EXCHANGE RATE:</label>
                    <money
                      v-model="exchangeRate"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: 'S/ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="form-control"
                      :class="
                        errors[0] && vmoneyValidate ? 'border-danger' : ''
                      "
                    />
                  </ValidationProvider>
                </b-col>
                <b-col class="mt-1">
                  <b-form-group
                    label="CURRENCY:"
                    label-for="input-2"
                  >
                    <div>
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <div style="display: flex; justify-content: center">
                          <b-form-radio
                            v-model="currency"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="'$'"
                            class="mr-3"
                          >$</b-form-radio>
                          <b-form-radio
                            v-model="currency"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="'S/'"
                          >S/</b-form-radio>
                        </div>
                      </b-form-group>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="DESCRIPTION:"
                    label-for="input-2"
                  >
                    <b-form-textarea
                      v-model="descriptionProduct"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span>
                        <strong>MERCHANDISING</strong>
                      </span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col>
                  <label>ID TRANSACTION</label>
                  <b-form-input
                    placeholder="ID Transaction"
                    v-model="idTransaction"
                  >
                  </b-form-input>
                </b-col> -->
                <b-col>
                  <ValidationProvider v-slot="{ errors }" rules="required|validate-amount">
                    <label>AMOUNT EXPENSE:</label>
                    <money
                      v-model="newAmountExpense"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: currency == '$' ? '$ ' : 'S/ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="form-control"
                      :class=" errors[0] && vmoneyValidate ? 'border-danger' : ''
                      "
                    />
                    <span v-if="errors[0] && vmoneyValidate " class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-col>
                <!-- <b-col>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label>AMOUNT INCOME</label>
                    <money
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: currency == '$' ? '$ ' : 'S/ ',
                        precision: 2,
                        masked: false,
                      }"
                      v-model="amountIncome"
                      class="form-control"
                      :class="{
                        'border border-danger': errors[0] && vmoneyValidate,
                      }"
                    ></money>
                  </ValidationProvider>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col class="mt-1">
                  <b-form-group
                    label="MERCHANDISING COMMENT:"
                    label-for="input-2"
                  >
                    <b-form-textarea
                      v-model="commentaryMerch"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="4">
              <label>PRODUCT IMAGE:</label>
              <div
                class="form-group"
                style="
                    border: 5px solid black
                    border-radius: 15px;
                    padding: 10px;"
              >
                <div class="image-upload">
                  <input
                    id="file_input_1"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    hidden
                    :disabled="!viewEdit && productId ? true : false"
                    @change="getImage"
                  >
                  <div
                    class="form-group"
                    :style="
                      viewEdit || !productId
                        ? 'cursor: pointer'
                        : 'cursor: not-allowed'
                    "
                  >
                    <figure @click="inputImage()">
                      <img
                        width="100%"
                        height="80%"
                        :src="image"
                      >
                    </figure>
                  </div>
                </div>
              </div>

              <div
                v-if="productId"
                class="form-group"
                style="text-align: center"
              >
                <div v-if="!viewEdit">
                  <feather-icon
                    icon="EditIcon"
                    size="25"
                    class="cursor-pointer mr-1"
                    style="color: green"
                    @click="clickEdit(1)"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="25"
                    class="cursor-pointer mr-1"
                    style="color: red"
                    @click="saveImage(1)"
                  />
                </div>
                <div v-else>
                  <feather-icon
                    icon="SaveIcon"
                    size="25"
                    class="cursor-pointer mr-1"
                    style="color: blue"
                    @click="saveImage(2)"
                  />
                  <feather-icon
                    icon="XCircleIcon"
                    size="25"
                    class="cursor-pointer mr-1"
                    style="color: red"
                    @click="clickEdit(2)"
                  />
                </div>
              </div>

              <!-- Valoration -->
              <div
                v-for="rate in rates"
                :key="rate.id"
                class="d-flex"
              >
                <template v-if="rate.id != 4">
                  <div class="d-flex align-items-center">
                    <p class="p-0 m-0">
                      {{ rate.name }}:
                    </p>
                  </div>
                  <div>
                    <b-form-rating
                      v-model="rate.value"
                      variant="warning"
                      size="sm"
                      no-border
                      class="p-0 m-0"
                      :readonly="productId != null"
                    />
                  </div>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>
      </ValidationObserver>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="idProduct ? editMerchandising() : saveMerchandising()"
        >SAVE</b-button>
      </template>
    </b-modal>
    <!-- MODAL REGISTER CLIENT -->
    <b-modal
      v-model="modalAddClient"
      modal
      title="REGISTER CLIENT"
      ok-title="SAVE"
      @ok.prevent="registerClientLog"
    >
      <div>
        <ValidationObserver ref="formClient">
          <b-row cols="2">
            <b-col>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
              >
                <b-form-group label="FIRST NAME:">
                  <b-form-input
                    v-model="firstName"
                    placeholder="..."
                    :class="{ 'border border-danger': errors[0] }"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <b-form-group label="LAST NAME:">
                <b-form-input
                  v-model="lastName"
                  placeholder="..."
                />
              </b-form-group>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
    <vendor-modal
      v-if="modalAddProvider"
      :type-modal="'ADD'"
      :opened-from="'merchandising'"
      @closeModal="closeModalAddProvider"
      @refreshTable="getSelectProvider"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import vSelect from 'vue-select';
import { VueAutosuggest } from 'vue-autosuggest';
import VendorModal from '@/views/logistic/views/vendors/views/modals/VendorModal.vue';
import BillsService from '@/views/logistic/views/bills/service/bills.service.js';
import MerchandisingService from '../services/merchandising.service';

export default {
  components: {
    vSelect,
    VueAutosuggest,
    VueGoogleAutocomplete,
    VendorModal,
  },
  props: {
    productId: {
      type: Object,
    },
  },
  data() {
    return {
      optionProvider: [],
      optionProgram: [],
      listClient: [],
      // children modals
      modalAddClient: false,
      modalAddProvider: false,
      // V money
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
      },
      vmoneyValidate: false,
      currency: '$',
      onControl: false,
      viewImage: null,
      product: {
        images: '',
      },
      urlImage: '/assets/images/product.png',
      viewEdit: false,
      imageUrl: '',
      saveUrlImage: '',
      // data
      idClient: '',
      idProgram: '',
      idProvider: '',
      idCountry: 173,
      nomProduct: '',
      idProduct: '',
      price: 0,
      exchangeRate: 0,
      quantityProduct: '',
      descriptionProduct: '',
      idTransaction: '',
      amountExpense: '',
      // amountIncome: 0,
      commentaryMerch: '',
      firstName: '',
      lastName: '',
      nomProvider: '',
      clientText: '',
      clientAccountId: '',
      full_name: '',
      rates: [],
      optionCountries: [],
    };
  },
  watch: {
    async idProvider() {
      await this.getRates();
    },
  },
  async created() {
    this.onControl = true;
    try {
      this.addPreloader();
      await Promise.all([
        this.getSelectProvider(),
        this.getExchangeRate(),
        this.getCountries(),
        this.getSelectProgram(),
        this.getLogProduct(),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      this.removePreloader();
    }
  },
  methods: {
    selectHandler(data) {
      this.clientAccountId = data.item.id;
      this.full_name = data.item.full_name;
      this.statusSelected = false;
    },
    getSuggestionValue(suggestion) {
      this.clientAccountId = suggestion.item.id;
      return suggestion.item.full_name;
    },
    async getExchangeRate() {
      if (this.productId) return;
      try {
        const { data } = await BillsService.getExchangeRate();
        this.exchangeRate = data.venta;
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      }
    },
    async getCountries() {
      try {
        const { data } = await BillsService.getCountries();
        this.optionCountries = data;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    clickEdit(value) {
      if (value == 1) {
        this.viewEdit = true;
      } else {
        this.viewEdit = false;
        this.viewImage = this.saveUrlImage;
      }
    },
    getImage(e) {
      const file = e.target.files[0];
      this.product.images = file;
      this.uploadImage(file);
    },
    uploadImage(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    inputImage() {
      document.getElementById('file_input_1').click();
    },
    async saveImage(value) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        this.viewEdit = false;

        const formData = new FormData();
        formData.append('productId', this.productId.id);
        formData.append('images', this.product.images);
        formData.append('value', value);
        this.addPreloader();
        const data = await MerchandisingService.editImageMerchandising(
          formData,
        );

        if (value == 1) {
          this.viewImage = this.urlImage;
          this.saveUrlImage = this.viewImage;
        } else {
          this.saveUrlImage = this.viewImage;
        }
        this.showToast();
        this.removePreloader();
        this.$emit('reloadGrilla');
      }
    },
    async getRates() {
      if (this.productId == null) {
        const params = {
          vendorId: this.idProvider,
        };
        try {
          const { data } = await MerchandisingService.getRates(params);
          this.rates = data.map(rate => ({
            ...rate,
            value: 0,
          }));
        } catch (error) {
          this.showErrorSwal();
        }
      }
    },
    openModalAddClient() {
      this.modalAddClient = true;
    },
    closeModalAddClient() {
      this.modalAddClient = false;
    },
    openModalAddProvider() {
      this.modalAddProvider = true;
    },
    closeModalAddProvider() {
      this.modalAddProvider = false;
    },
    registerClient() {},
    registerProvider() {},
    closeModal() {
      this.$emit('close');
    },
    justCloseModal() {
      this.$emit('justClose');
    },
    async saveMerchandising() {
      this.vmoneyValidate = true;
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        if(this.amountExpense > 0) {
          try {
          this.vmoneyValidate = false;
          const result = await this.showConfirmSwal();
          if (result.value) {
            this.addPreloader();
            const formData = new FormData();
            formData.append('idClient', this.clientAccountId);
            formData.append('idProgram', this.idProgram);
            formData.append('idProvider', this.idProvider);
            formData.append('idCountry', this.idCountry);
            formData.append('nomProduct', this.nomProduct);
            formData.append('priceProduct', this.price);
            formData.append('exchangeRate', this.exchangeRate);
            formData.append('currencyProduct', this.currency);
            formData.append('quantityProduct', this.quantityProduct);
            formData.append('detailProduct', this.descriptionProduct);
            formData.append('idTransaction', this.idTransaction);
            formData.append('amountExpense', this.amountExpense);
            // formData.append("amountIncome", this.amountIncome);
            formData.append('commentaryProduct', this.commentaryMerch);
            formData.append('imageUrl', this.imageUrl);
            formData.append('images', this.product.images);
            formData.append('saveUrlImage', this.saveUrlImage);
            formData.append('rates', JSON.stringify(this.rates).toString());
            formData.append('user_id', this.currentUser.user_id);
            await MerchandisingService.registerLogProduct(formData);
            this.showToast();
            this.newBrand = '';
            this.removePreloader();
            this.closeModal();
          }
        } catch (error) {
          this.showErrorSwal(error);
          this.removePreloader();
        }
        }else{
          this.showInfoSwal("THE AMOUNT EXPENSE CANNOT BE 0")       
        }
        
      }
    },
    async editMerchandising() {
      this.vmoneyValidate = true;
      const success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          this.vmoneyValidate = false;
          const result = await this.showConfirmSwal();
          if (result.value) {
            this.addPreloader();
            const formData = new FormData();
            formData.append('idProduct', this.idProduct);
            formData.append('idClient', this.clientAccountId);
            formData.append('idProgram', this.idProgram);
            formData.append('idProvider', this.idProvider);
            formData.append('idCountry', this.idCountry);
            formData.append('nomProduct', this.nomProduct);
            formData.append('priceProduct', this.price);
            formData.append('exchangeRate', this.exchangeRate);
            formData.append('currencyProduct', this.currency);
            formData.append('quantityProduct', this.quantityProduct);
            formData.append('detailProduct', this.descriptionProduct);
            formData.append('idTransaction', this.idTransaction);
            formData.append('amountExpense', this.amountExpense);
            // formData.append("amountIncome", this.amountIncome);
            formData.append('commentaryProduct', this.commentaryMerch);
            formData.append('images', this.product.images);
            formData.append('saveUrlImage', this.saveUrlImage);
            formData.append('imageUrl', this.imageUrl);
            await MerchandisingService.editLogProduct(formData);
            this.showToast();
            this.newBrand = '';
            this.removePreloader();
            this.closeModal();
          }
        } catch (error) {
          this.showErrorSwal(error);
          this.removePreloader();
        }
      }
    },

    async getClients() {
      const parameters = {
        idProgram: this.idProgram,
        clientText: this.clientText,
      };
      try {
        const response = await MerchandisingService.getClients(parameters);
        if (response.status == 200) {
          this.listClient = [{ data: [...response.data] }];
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getLogProduct() {
      if (!this.productId) return;
      try {
        const response = await MerchandisingService.getLogProduct({
          idProduct: this.productId.id,
        });
        this.idProduct = response.data[0].id;
        this.clientAccountId = response.data[0].client_id;
        this.full_name = response.data[0].full_name;
        this.clientText = response.data[0].full_name;
        this.idProgram = response.data[0].idProgram;
        this.idProvider = response.data[0].idvendor;
        this.idCountry = response.data[0].idcountry;
        this.nomProduct = response.data[0].product;
        this.price = response.data[0].price;
        this.exchangeRate = response.data[0].exchange_rate;
        this.currency = response.data[0].currency;
        this.quantityProduct = response.data[0].quantity;
        this.descriptionProduct = response.data[0].detail;
        // this.idTransaction = response.data[0].id_transaction;
        this.amountExpense = response.data[0].amount_expense;
        // this.amountIncome = response.data[0].amount_income_product;
        this.commentaryMerch = response.data[0].commentary;
        this.viewImage = response.data[0].image_product;
        this.saveUrlImage = response.data[0].image_product;
        this.imageUrl = response.data[0].image_product;
        this.rates = JSON.parse(response.data[0].rates) || [];

        const { data: ratesData } = await MerchandisingService.getRates({
          vendorId: this.idProvider,
        });

        // Create a mapping of keys present in this.rates
        const ratesKeys = new Set(this.rates.map(rate => rate.name));

        // Check for missing keys in ratesData and add them with values set to zero in this.rates
        ratesData.forEach(rateData => {
          if (!ratesKeys.has(rateData.name)) {
            this.rates.push({ ...rateData, value: 0 });
            ratesKeys.add(rateData.name);
          }
        });

        this.rates = this.rates.map(rate => ({ ...rate, value: rate.score }));
        this.rates.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0; // Si los nombres son iguales, no cambia el orden.
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getSelectProvider() {
      try {
        const response = await MerchandisingService.getProviderList({});
        if (response.status == 200) {
          this.optionProvider = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong!',
        );
      }
    },
    async getSelectProgram() {
      try {
        const response = await MerchandisingService.getProgramList({});
        if (response.status == 200) {
          this.optionProgram = response.data;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          'danger',
          'top-right',
          'Error',
          'XIcon',
          'Something went wrong!',
        );
      }
    },
    async registerClientLog() {
      const success = await this.$refs.formClient.validate();
      if (!success) {
      } else {
        const result = await this.showConfirmSwal();
        if (result.value) {
          this.addPreloader();
          await MerchandisingService.registerClientLog({
            firstName: this.firstName,
            lastName: this.lastName,
          });
          this.showToast();
          this.firstName = '';
          this.lastName = '';
          this.removePreloader();
          this.closeModalAddClient();
        }
      }
    },
    async registerProviderLog() {
      const success = await this.$refs.formProvider.validate();
      if (!success) {
      } else {
        const result = await this.showConfirmSwal();
        if (result.value) {
          this.addPreloader();
          await MerchandisingService.registerProviderLog({
            nomProvider: this.nomProvider,
          });
          this.showToast();
          this.nomProvider = '';
          this.removePreloader();
          this.closeModalAddProvider();
          this.getSelectProvider();
        }
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    image() {
      if (!this.viewImage) {
        return this.urlImage;
      }
      return this.viewImage;
    },
    newAmountExpense: {
      get() {
        return this.price * this.quantityProduct;
      },
      set(newValue) {
        this.amountExpense = newValue;
      },
    },
  },
};
</script>

<style scoped>
.w-82 {
  width: 82.64px;
}
.input-background-white {
  background: white;
}
</style>
