import { amgApi } from '@/service/axios';

class MerchandisingService {
  async viewLogProduct() {
    const data = await amgApi.post('/logistics/merchandising/view-log-product');
    return data;
  }

  async getClientList() {
    const data = await amgApi.post('/logistics/merchandising/get-client-list');
    return data;
  }

  async getClients(params){
    try {
        const data = await amgApi.post("/logistics/merchandising/get-client", params)
        return data
    } catch (error) {
        console.error(error);
    }
    
}

  async getProviderList() {
    const data = await amgApi.post('/logistics/merchandising/get-provider-list');
    return data;
  }

  async getProgramList() {
    const data = await amgApi.post('/logistics/merchandising/get-program-list');
    return data;
  }

  async registerLogProduct(params) {
    const data = await amgApi.post('/logistics/merchandising/register-log-product', params);
    return data;
  }

  async deleteLogProduct(params) {
    const data = await amgApi.post('/logistics/merchandising/delete-log-product', params);
    return data;
  }

  async getLogProduct(params) {
    const data = await amgApi.post('/logistics/merchandising/get-log-product', params);
    return data;
  }

  async editLogProduct(params) {
    const data = await amgApi.post('/logistics/merchandising/edit-log-product', params);
    return data;
  }

  async editImageMerchandising(params) {
    const data = await amgApi.post('/logistics/merchandising/edit-image-merchandising', params);
    return data;
  }

  async registerClientLog(params) {
    const data = await amgApi.post('/logistics/merchandising/register-client-log', params);
    return data;
  }

  async registerProviderLog(params) {
    const data = await amgApi.post('/logistics/merchandising/register-provider-log', params);
    return data;
  }

  async getRates(params) {
    try {
        const data = amgApi.post("/logistics/bills/get-rates", params);
        return data;
    } catch (error) {
        throw error;
    }
}
}

export default new MerchandisingService();
